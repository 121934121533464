$(document).ready(function() {
    $('.on-click-confirm').click(function(event) {
        // Bestätigungsdialog anzeigen
        var confirmed = confirm("Sind Sie sicher?");

        // Wenn der Benutzer bestätigt hat, fortfahren
        if (confirmed === false) {
            event.preventDefault();
        }
    });
});
